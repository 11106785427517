<template>
  <div>
    <link
      rel="stylesheet"
      href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
      integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
      crossorigin="anonymous"
    />

    <main>
      <div class="header">
        <div class="header-logo">
          <router-link to="/landing" class="d-flex justify-content-center">
            <img
              :src="require('../assets/images/horizontal_logo.png')"
              width="150px"
              height="30px"
            />
          </router-link>
        </div>

        <!-- <div class="bottom-item">
                    <a class="d-flex flex-column" @click="logout">
                        <inline-svg :src="require('../assets/icon/logout.svg')" width="32" height="32"
                            aria-label="logout"></inline-svg>
                        <span>{{ $t("Logout") }}</span>
                    </a>
                </div> -->
      </div>
      <div class="divider mb-5 mb-md-0 mb-lg-0">
        <!-- header -->
        <div class="sidebar" id="sidebar">
          <div
            class="d-flex flex-column justify-content-between align-items-center h-100"
          >
            <div class="w-100">
              <div class="logo">
                <router-link
                  to="/landing"
                  class="d-flex justify-content-center"
                >
                  <img :src="require('../assets/images/savvix_logo.png')" />
                </router-link>
              </div>

              <b-nav vertical class="w-100">
                <b-nav-item class="sidebar-item" to="/wallet">
                  <inline-svg
                    :src="require('../assets/icon/new-icon/wallet.svg')"
                    width="30"
                    height="30"
                    aria-label="wallet"
                  ></inline-svg>
                  <span>{{ $t("Wallet") }}</span>
                </b-nav-item>

                <b-nav-item
                  v-if="userType == 'reuse'"
                  class="sidebar-item"
                  to="/request/history"
                >
                  <inline-svg
                    :src="require('../assets/icon/new-icon/history.svg')"
                    width="30"
                    height="30"
                    aria-label="transaction"
                  ></inline-svg>
                  <span>{{ $t("Request History") }}</span>
                </b-nav-item>

                <b-nav-item class="sidebar-item" to="/transaction">
                  <inline-svg
                    :src="require('../assets/icon/new-icon/transaction.svg')"
                    width="30"
                    height="30"
                    aria-label="swap"
                  ></inline-svg>
                  <span>{{ $t("Transaction") }}</span>
                </b-nav-item>

                <b-nav-item
                  v-if="viewOperator"
                  class="sidebar-item"
                  to="/operators"
                >
                  <inline-svg
                    :src="require('../assets/icon/new-icon/operator.svg')"
                    width="30"
                    height="30"
                    aria-label="operators"
                  ></inline-svg>
                  <span>{{ $t("Operators") }}</span>
                </b-nav-item>

                <b-nav-item
                  v-if="viewOperator"
                  class="sidebar-item"
                  to="/withdrawalapproval"
                >
                  <div>
                    <inline-svg
                      :src="require('../assets/icon/new-icon/approve.svg')"
                      width="30"
                      height="30"
                      aria-label="withdrawalapprove"
                    ></inline-svg>
                  </div>
                  <span>{{ $t("Withdrawal Approval") }}</span>
                </b-nav-item>

                <b-nav-item class="sidebar-item" to="/profile">
                  <inline-svg
                    :src="require('../assets/icon/new-icon/profile.svg')"
                    width="30"
                    height="30"
                    aria-label="profile"
                  ></inline-svg>
                  <span>{{ $t("Profile") }}</span>
                </b-nav-item>

                <b-nav-item
                  v-if="isRoot"
                  class="sidebar-item"
                  to="/coinsetting"
                >
                  <inline-svg
                    :src="require('../assets/icon/coin-setting.svg')"
                    width="30"
                    height="30"
                    aria-label="coinsetting"
                  ></inline-svg>
                  <span>{{ $t("Coin Setting") }}</span>
                </b-nav-item>

                <!-- <b-nav-item v-if="isRoot" class="sidebar-item" to="/gasFee">
                                    <inline-svg :src="require('../assets/icon/transaction-gas-fee.svg')" width="30" height="30"
                                        aria-label="gasfee"></inline-svg>
                                    <span>{{ $t("Gas Fee") }}</span>
                                </b-nav-item> -->

                <b-nav-item class="sidebar-item" @click="logout">
                  <inline-svg
                    :src="require('../assets/icon/new-icon/logout.svg')"
                    width="30"
                    height="30"
                    aria-label="logout"
                  ></inline-svg>
                  <span>{{ $t("Logout") }}</span>
                </b-nav-item>
              </b-nav>
            </div>
            <div class="w-100">
              <b-nav vertical>
                <b-nav-item
                  class="sidebar-item"
                  @click="swagger == 'null' ? getSwagger() : openSwagger()"
                >
                  <inline-svg
                    :src="require('../assets/icon/new-icon/folder.svg')"
                    width="32"
                    height="30"
                    aria-label="logout"
                  ></inline-svg>
                  <span>{{ $t("Api Docs") }}</span>
                </b-nav-item>
              </b-nav>
            </div>
          </div>
        </div>

        <div class="overflow-auto">
          <router-view></router-view>
        </div>

        <!-- Bottom Navigation Bar -->
        <div class="bottom-bar">
          <div class="bottom-item">
            <router-link class="d-flex flex-column" to="/wallet">
              <inline-svg
                :src="require('../assets/icon/wallet.svg')"
                width="30"
                height="30"
                aria-label="wallet"
              ></inline-svg>
              <span>{{ $t("Wallet") }}</span>
            </router-link>
          </div>

          <div v-if="isRoot" class="bottom-item">
            <router-link class="d-flex flex-column" to="/withdrawalapproval">
              <inline-svg
                class="withdraw-approve"
                :src="require('../assets/icon/new-icon/approve.svg')"
                width="30"
                height="30"
                aria-label="withdrawalapprove"
              ></inline-svg>
              <span>{{ $t("W. Approval") }}</span>
            </router-link>
          </div>
          <div v-else-if="userType == 'reuse'" class="bottom-item">
            <router-link class="d-flex flex-column" to="/request/history">
              <inline-svg
                class="withdraw-approve"
                :src="require('../assets/icon/new-icon/history.svg')"
                width="30"
                height="30"
                aria-label="transaction"
              ></inline-svg>
              <span>{{ $t("R. History") }}</span>
            </router-link>
          </div>

          <div class="bottom-item">
            <router-link class="d-flex flex-column" to="/transaction">
              <inline-svg
                :src="require('../assets/icon/transaction.svg')"
                width="30"
                height="30"
                aria-label="swap"
              ></inline-svg>
              <span>{{ $t("Transaction") }}</span>
            </router-link>
          </div>
          <!-- <div v-if="viewOperator" class="bottom-item">
            <router-link class="d-flex flex-column" to="/operators">
              <inline-svg
                :src="require('../assets/icon/operators.svg')"
                width="25"
                height="30"
                aria-label="operators"
              ></inline-svg>
              <span>{{ $t("Operators") }}</span>
            </router-link>
          </div> -->
          <div class="bottom-item">
            <div class="d-flex flex-column" style="align-items: center">
              <inline-svg
                :src="require('../assets/icon/sidebar-icon.svg')"
                width="25"
                height="30"
                aria-label="sidebar"
                v-b-toggle.sidebar-backdrop
                class="sidebar-btn"
              ></inline-svg>
              <span>{{ $t("More") }}</span>
            </div>
          </div>
        </div>
      </div>
    </main>
    <b-sidebar
      id="sidebar-backdrop"
      title=""
      :backdrop-variant="variant"
      backdrop
      shadow
      right
    >
      <div class="px-4 py-5 sidebar-container">
        <router-link to="/profile">
          <div class="icon-wrap">
            <inline-svg
              :src="require('../assets/icon/new-icon/profile.svg')"
              width="30"
              height="30"
              aria-label="profile"
            ></inline-svg>
          </div>
          <div class="sidebar-label">
            <span>{{ $t("Profile") }}</span>
          </div>
        </router-link>
        <router-link to="/operators" v-if="viewOperator">
          <div class="icon-wrap">
            <inline-svg
              :src="require('../assets/icon/new-icon/operator.svg')"
              width="30"
              height="30"
              aria-label="operators"
            ></inline-svg>
          </div>
          <div class="sidebar-label">
            <span>{{ $t("Operators") }}</span>
          </div>
        </router-link>
        <router-link to="/request/history" v-if="isRoot && userType == 'reuse'">
          <div class="icon-wrap">
            <inline-svg
              class="withdraw-approve"
              :src="require('../assets/icon/new-icon/history.svg')"
              width="30"
              height="30"
              aria-label="transaction"
            ></inline-svg>
          </div>
          <div class="sidebar-label">
            <span>{{ $t("Request History") }}</span>
          </div>
        </router-link>
        <router-link to="/coinsetting" v-if="isRoot">
          <div class="icon-wrap">
            <inline-svg
              :src="require('../assets/icon/new-icon/coin-setting.svg')"
              width="30"
              height="30"
              aria-label="coinsetting"
            ></inline-svg>
          </div>
          <div class="sidebar-label">
            <span>{{ $t("Coin Setting") }}</span>
          </div>
        </router-link>
        <a class="" @click="logout">
          <div class="icon-wrap">
            <inline-svg
              :src="require('../assets/icon/logout.svg')"
              width="32"
              height="32"
              aria-label="logout"
            ></inline-svg>
          </div>
          <div class="sidebar-label">
            <span>{{ $t("Logout") }}</span>
          </div>
        </a>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import api from "../helper/api.js";

export default {
  name: "Layout",
  components: {
    InlineSvg,
  },
  data() {
    return {
      menuTitle: "open",
      menuOpened: false,
      icon: "fas fa-bars",
      userType: localStorage.getItem("userType"),
      viewOperator: [
        localStorage.getItem("inviteOperator"),
        localStorage.getItem("settingOperator"),
        localStorage.getItem("root"),
      ].includes("true"),
      swagger: localStorage.getItem("swagger"),
      isRoot: localStorage.getItem("root") === "true",
    };
  },
  mounted() {
    console.log(this.isRoot);
  },
  watch: {
    // $route () {
    //   if (window.getComputedStyle(document.getElementById('menu')).display === 'block') {
    //     document.getElementById('sidebar').style.display = 'none'
    //     this.menuTitle = 'open'
    //   }
    // }
  },
  methods: {
    test() {
      this.$i18n.locale = "en";
    },
    openMenu() {
      if (this.menuTitle === "close") {
        document.getElementById("sidebar").style.display = "none";
        this.menuTitle = "open";
      } else {
        document.getElementById("sidebar").style.display = "block";
        this.menuTitle = "close";
      }
    },
    openSwagger() {
      window.open(this.swagger, "_blank");
    },
    async getSwagger() {
      let params = {};
      try {
        const response = await api.apiCall(
          "core",
          "POST",
          "/swagger-token",
          params
        );
        console.log(response);
        // this.$swal({
        //     text: this.$t("Get swagger access success"),
        //     icon: 'success',
        //     confirmButtonColor: '#33AD4A',
        // });
        // window.location.reload();
        this.swagger = response.message.api_link;
        window.open(response.message.api_link, "_blank");
      } catch (error) {
        this.$swal({
          text: this.$t("Some error is occur, please try again later"),
          icon: "error",
        });
        console.error(error);
      }
    },
    async logout() {
      try {
        await api.apiCall("core", "GET", "/operators/logout");
        this.$router.push({ name: "Login" });
        localStorage.clear();
        return;
      } catch (error) {
        console.log("baka " + error);
        this.$swal({
          text: error.message,
          icon: "error",
        });
        return;
      }
    },
  },
};
</script>

<style>
.b-sidebar-header {
  min-height: 50px;
}

.sidebar-btn:focus {
  outline: none;
}

.sidebar-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-direction: column;
}

.sidebar-container a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
  padding-left: 10px;
}

.sidebar-container .withdraw-approve path,
.bottom-item .withdraw-approve path {
  fill: white !important;
}

.sidebar-label {
  /* min-width: 150px; */
  text-align: right;
  font-weight: 600;
  font-size: 20px;
  color: black;
}

.router-link-active .sidebar-label {
  color: var(--info);
}

button:focus {
  outline: none;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: none !important;
}

.divider .sidebar {
  display: none;
}

nav {
  margin: 0;
  height: 56px;
  padding: 0 16px 0 24px;
  color: #2c3e50;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  flex-wrap: nowrap;
}

nav > span {
  display: block;
  position: relative;
  font-size: 20px;
  line-height: 1;
  letter-spacing: 0.02em;
  font-weight: 400;
  box-sizing: border-box;
  padding-top: 16px;
  float: right;
}

.divider {
  background: rgb(0, 0, 0, 0.05);
  margin-top: 70px;
}

.sidebar {
  background: #ffffff;
}

#sidebar ul.nav {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  height: 400px;
  overflow: auto;
  flex-wrap: nowrap;
}

.logo {
  padding: 25px 0px;
}

.logo img {
  width: 60%;
  max-width: 150px;
}

.sidebar-item a {
  display: flex;
  align-items: center;
  color: var(--inactive);
  margin-left: 8%;
  height: 50px;
  margin-top: 5px;
}

.sidebar svg {
  padding: 5px 0;
  width: 50px;
}

.sidebar-item a:hover,
.router-link-exact-active,
.router-link-active,
.router-link-exact-active path,
.router-link-active path,
.router-link-active path .sidebar-item #sidebar_path,
.router-link-exact-active path .sidebar-item #sidebar_path path {
  background-color: #f2f2f2;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  color: var(--second-text-color);
  text-decoration: none;
  stroke: var(--primary) !important;
}

.router-link-exact-active > span {
  color: var(--second-text-color);
  color: var(--dark);
}

.sidebar-item a:hover path,
.sidebar-item a:hover #sidebar_path,
.sidebar-item a:hover #sidebar_path path {
  stroke: var(--primary);
}

.sidebar-item #sidebar_path,
.sidebar-item #sidebar_path path {
  fill: var(--info-50);
}

.sidebar-item span {
  margin-left: 12px;
}

.menu {
  font-size: 20px;
  padding: 12px;
  position: fixed;
  top: 0;
  right: 0;
  color: black;
}

.bottom-bar {
  position: fixed;
  background-color: white;
  bottom: 0;
  height: 60px;
  width: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 100;
  display: none;
}

.bottom-item {
  width: 20%;
  font-size: 12px;
}

.bottom-item a {
  align-items: center;
  color: #c1c1c1;
}

.header {
  position: fixed;
  top: 0;
  height: 70px;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  z-index: 100;
  display: none;
}

@media only screen and (min-width: 768px) {
  .divider .sidebar {
    display: block;
  }

  .menu {
    display: none !important;
  }

  #sidebar ul.nav {
    height: auto;
  }

  .divider {
    margin: 0;
  }
}

@media only screen and (max-height: 600px) {
  #sidebar ul.nav {
    height: 350px;
    flex-wrap: nowrap !important;
    scrollbar-width: none;
  }
}

@media only screen and (max-width: 450px) {
  .bottom-bar {
    display: flex;
  }

  .sidebar-item a:hover,
  .router-link-exact-active,
  .router-link-active,
  .router-link-exact-active path,
  .router-link-active path,
  .router-link-active path .sidebar-item #sidebar_path,
  .router-link-exact-active path .sidebar-item #sidebar_path path {
    background-color: transparent;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    color: var(--second-text-color);
    text-decoration: none !important;
    fill: var(--primary) !important;
  }

  .header {
    display: flex;
    justify-content: space-between;
  }
}
</style>
