import axios  from "axios"
import router from '../router';

export default {
    async apiCall(type = 'core', method, endpoint, params = {}){

        let baseUrl;
        let config = {}

        if(method == 'GET') {
            config = {
                headers: {
                    // host:  'test.savvixpay.com',
                //     Authorization: 'Bearer ' + localStorage.getItem("token"),
                },
                params: params,
                withCredentials:true
            }
        } else if(method == 'POST') {
            config = {
                headers: {
                    // host:  'test.savvixpay.com',
                    // Authorization: 'Bearer ' + localStorage.getItem("token"),
                },
                withCredentials:true
            }
        } else {
            config = {
                withCredentials:true,
                data: params
            }
        }
        

        switch(type){
            case 'trx':
                baseUrl = process.env.VUE_APP_TRX_BASE_URL;
            break;

            case 'bsc':
                baseUrl = process.env.VUE_APP_BSC_BASE_URL;
            break;

            case 'eth':
                baseUrl = process.env.VUE_APP_ETH_BASE_URL;
            break;

            default:
                baseUrl = process.env.VUE_APP_CORE_BASE_URL;
        }

        try{
            let response;
            let api;

            switch(method){
                case "GET":
                    api = await axios.get(baseUrl+endpoint, config)
                    response = api.data;
                break;

                case "POST":
                    api = await axios.post(baseUrl+endpoint, params, config)
                    response = api.data;
                break;

                case "DELETE":
                    api = await axios.delete(baseUrl+endpoint, config);
                    response = api.data;
                break;
            }

            return response;

        }catch(error){
            console.log(error);

            if(error.response.status == 401 || error.response.status == 403) {
                localStorage.clear();
                router.push({ name: 'Login' }).catch(() => {})
            }

            throw error.response.data
            // return error;
        }
    }
}